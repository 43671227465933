// import $ from 'jquery';
import Swiper from 'swiper/bundle';
// import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);


const isSmartPhone = () =>  {
  // if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
  if (window.matchMedia && window.innerWidth < 768) {
    return true;
  } else {
    return false;
  }
};



/**
 * 現在一から上スクロールでクラスふよ
 */
class ScrollDownToggleClass {
  constructor(target, border = 56) {
    this.target = target;
    this.border = border;
    this.offset = 0;
    this.lastPosition = 0;
    this.ticking = false;
  }
  toggleClass() {
    this.lastPosition = window.scrollY;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        if (this.lastPosition > this.border) {
          if (this.lastPosition > this.offset) {
            this.target.classList.add('is-hide');
          } else {
            this.target.classList.remove('is-hide');
          }
          this.offset = this.lastPosition;
        }
        this.ticking = false;
      });
      this.ticking = true;
    }
  }
}
const header = document.querySelector(".js-header");
const headerOpen = document.querySelector(".js-header-open");
const scrollDownToggleClass = new ScrollDownToggleClass(header);
window.addEventListener('scroll', (e) => {
  if (!headerOpen.checked) {
    scrollDownToggleClass.toggleClass()
  }
  if (window.scrollY > 50) {
    header.classList.add('is-top')
  } else {
    header.classList.remove('is-top')
  }

});
/**
 * @desc スクロールであるポイントを超えたらクラスを付与
 * @param {*} targetEl  
 * @param {*} togglePointEl 
 * @param {*} toggleClass 
 */
const scrollInToggleClass = (targetEl, togglePointEl, toggleClass) => {

  if (togglePointEl) {
    const observer1 = new IntersectionObserver((entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          targetEl.classList.remove(toggleClass);
        } else {
          targetEl.classList.add(toggleClass);
        }
      }
    });
    observer1.observe(togglePointEl);
  } else {
    if (targetEl) {
      targetEl.classList.add(toggleClass);
    }
  }
};


scrollInToggleClass(header, document.querySelector(".js-hero"), "is-active")
// scrollInToggleClass(document.querySelector(".js-header-fresh"), document.querySelector(".js-hero"), "is-active")

/**
 * @desc 一文字づつ文字をラップする
 * @param {*} target 
 * @returns 一文字つづspanでラップしたテキスト
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes];
  let returnText = '';

  for (const node of nodes) {
    if (node.nodeType == 3) {
      const text = node.textContent.replace(/\r?\n/g, '');
      const splitText = text.split('');
      for (const char of splitText) {
        returnText += `<span>${char}</span>`;
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};

/* */

const hero = document.querySelector(".js-hero")
if (hero) {
  const heroTl = gsap.timeline();
  heroTl.from(hero.querySelector('.hero__mv img'), {
    scale: 1.2,
    duration: 1.75
  });
  gsap.fromTo(header, {
    y: "-100%",
    opacity: 0
  }, {
    duration: 1.5,
    opacity: 1,
    y: 0
  });
  gsap.fromTo(hero.querySelector('.hero__main picture'), {
    opacity: 0,
    y: 50,
  }, {
    duration: 1.7,
    y: 0,
    opacity: 1,
  });
}

const pageHero = document.querySelector('.js-page-hero');
if (pageHero) {
  const pageHeroTl = gsap.timeline();
  gsap.set(pageHero.querySelector('.c-hero__title-ruby'), {
    opacity: 0
  });
  gsap.set(pageHero.querySelector('.c-hero__title'), {
    opacity: 0
  });
  pageHeroTl.from(pageHero.querySelector('img'), {
    opacity: 0,
    scale: 1.25,
    duration: .8
  });
  pageHeroTl.to(pageHero.querySelector('.c-hero__title-ruby'), {
    delay: 0,
    opacity: 1,
  });
  pageHeroTl.to(pageHero.querySelector('.c-hero__title'), {
    opacity: 1,
  });

}
const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls.length!=0) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 30,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 1.25,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const scaleEffect = gsap.utils.toArray('.js-scale-effect1');
if (scaleEffect.length!=0) {
  scaleEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 60,
      scale: 1.25,
      opacity: 0,
    }, {
      y: 0,
      scale: 1,
      opacity: 1,
      duration: 1.5,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const rollEffect = gsap.utils.toArray('.js-x-effect1');
if (rollEffect.length!=0) {
  rollEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      x: "25%",
    }, {
      x: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const rollEffect2 = gsap.utils.toArray('.js-x-effect2');
if (rollEffect2.length!=0) {
  rollEffect2.forEach((box, index) => {
    gsap.fromTo(box, {
      x: "-25%",
    }, {
      x: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const yEffect = gsap.utils.toArray('.js-y-effect1');
if (yEffect.length!=0) {
  yEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      y: "25%",
    }, {
      y: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const yEffect2 = gsap.utils.toArray('.js-y-effect2');
if (yEffect2.length!=0) {
  yEffect2.forEach((box, index) => {
    gsap.fromTo(box, {
      y: "-25%",
    }, {
      y: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const staggerEffect1 = gsap.utils.toArray('.js-stagger-effect1');
if (staggerEffect1.length!=0) {
  staggerEffect1.forEach((box, index) => {
  gsap.fromTo(staggerEffect1, {
    y: 50,
    scale: 1.2,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    scale: 1,
    stagger: .5,
    duration: .8,
    scrollTrigger: {
      trigger: staggerEffect1,
      start: 'top bottom',
      end: 'bottom top',
    }
  });
  });
}
const staggerEffect2 = gsap.utils.toArray('.js-stagger-effect2');
if (staggerEffect2.length!=0) {
  // staggerEffect2.forEach((box, index) => {
  gsap.fromTo(staggerEffect2, {
    y: 70,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: 0.25,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect2,
      start: 'top bottom',
      end: 'bottom top',
    }
  });
  // });
}
const staggerEffect3 = gsap.utils.toArray('.js-stagger-effect3');
if (staggerEffect3.length!=0) {
  gsap.fromTo(staggerEffect3, {
    y: -150,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: .25,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect3,
      start: 'top bottom',
      end: 'bottom top',
    }
  });
}
const staggerEffect4 = gsap.utils.toArray('.js-stagger-effect4');
if (staggerEffect4.length!=0) {
  gsap.fromTo(staggerEffect4, {
    y: -150,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: .25,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect4,
      start: 'top bottom',
      end: 'bottom top',
    }
  });
}

const enteractive = gsap.utils.toArray('.js-enter-active');
if (enteractive) {
  enteractive.forEach((element, index) => {
    gsap.to(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top center',
        onEnter: () => element.classList.add('is-active'),
      }
    });
  });
}



// tl.from(spans, {
//   opacity: 0,
//   duration: 0.01,
//   stagger: 0.08,
// });

// const topRecruitLabel = gsap.utils.toArray('.top-recruit__label');
// if (topRecruitLabel.length!=0) {
//   topRecruitLabel.forEach((box, index) => {
//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: box,
//         start: 'top 90%',
//       }
//     });
//     tl.fromTo(box.querySelector('.bg'), {
//       scaleX: 0,
//     }, {
//       scaleX: 1,
//       duration: .5,
//     });
//     tl.fromTo(box.querySelector('.txt'), {
//       opacity: 0,
//       y: -15,
//     }, {
//       delay: .5,
//       duration: .5,
//       y: 0,
//       opacity: 1,
//     })
//   });
// }


// const scrollDownToggleClass = new ScrollDownToggleClass(document.getElementById('header'));
// window.addEventListener('scroll', () => {
//   scrollDownToggleClass.toggleClass()
// });



/****/
//

// mv animation

// $(function(){

//   $('.js-toggle').on('click', function(){
//     $(this).toggleClass('is-active')
//     $(this).next().slideToggle()
//   })
// })

// (function(){
//   const heroMv = document.querySelector('.hero__mv')
//   const count = 400; 
//   for (let i = 0 ; i < 400; i++) {
//     let block = document.createElement('div')
//     block.classList.add('box')
//     heroMv.appendChild(block)
//   }
//   const boxs = gsap.utils.toArray('.box');
//   if (boxs) {
//     gsap.from(boxs, {
//       // delay.05
//       top: 80,
//       left: 80,
//       stagger: .005,
//       // scale : 150
//       // y: 10,
//       // opacity: 1,
//     })
//   }
// })()



// gsap.set(document.querySelector(".js-mov"), {perspective:200});
// gsap.from(document.querySelector(".js-mov"), .05, {css:{autoAlpha:0}}, {css:{autoAlpha:1}, immediateRender:true});
// gsap.fromTo(document.querySelector(".js-mov"), {
//   transform: "rotate3d(-40, 10, 5, 75deg)"
// }, {
//   transform: "none",
//   duration: 3
// })
// gsap.from(document.querySelector(".js-mov"), {
//   rotateZ: "80",
//   skewY: -40,
//   // skewX: 40,
//   x: -10,
//   // y: -90,
//   scale: 1.05,
//   duration: 1.2
// });
// gsap.from(document.querySelector(".js-mov1"), {
//   rotate: "-305",
//   scale: .5,
//   duration: 1.2
// });
// gsap.from(document.querySelector(".js-mov2"), {
//   rotate: "-105",
//   skewY: 30,
//   x: 20,
//   skewX: 20,
//   scale: .5,
//   duration: 1.2
// });


(function(gsap){
  if(document.querySelector('.js-venu-block__logo1') || document.querySelector('.js-venu-block__logo2')) {
    gsap.from('.js-venu-block__logo1', {
      duration: 2,
      y: -150,
      scrollTrigger: {
        trigger: '.js-venu-block__logo1',
        scrub: 1,
      }
    })
    gsap.from('.js-venu-block__text1', {
      duration: 2,
      x: 50,
      scrollTrigger: {
        trigger: '.js-venu-block__text1',
        scrub: 1,
      }
    })
    gsap.from('.js-venu-block__logo2', {
      duration: 2,
      y: -150,
      scrollTrigger: {
        trigger: '.js-venu-block__logo2',
        scrub: 1,
      }
    })
    gsap.from('.js-venu-block__text2', {
      duration: 2,
      y: 50,
      scrollTrigger: {
        trigger: '.js-venu-block__text2',
        scrub: 1,
      }
    })

  }
}(gsap))





let
cursor = document.getElementById("cursor"),
follower = document.getElementById("follower");

let
pos = { x: 0, y: 0 }, // カーソル要素の座標
mouse = { x: pos.x, y: pos.y }, // マウスカーソルの座標
speed = 0.8; // 0.01〜1 数値が大きほど早い

// カーソルの座標を設定する
let cursorSetX = gsap.quickSetter(cursor, "x", "px");
let cursorSetY = gsap.quickSetter(cursor, "y", "px");

// 遅延するカーソルの座標を設定する
let followerSetX = gsap.quickSetter(follower, "x", "px");
let followerSetY = gsap.quickSetter(follower, "y", "px");

// // マウスカーソル座標を取得する
// document.addEventListener("mousemove", function(event) {
//   mouse.x = event.pageX;
//   mouse.y = event.pageY;
// });


// gsap.ticker.add(function() {
  
//   let dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
  
//   pos.x += (mouse.x - pos.x) * dt;
//   pos.y += (mouse.y - pos.y) * dt;
//   cursorSetX(pos.x);
//   cursorSetY(pos.y);
//   followerSetX(pos.x);
//   followerSetY(pos.y);
// });

// $("a").on({
//   "mouseenter": function() {
//     $(cursor).addClass("is-active");
//     $(follower).addClass("is-active");
//   },
//   "mouseleave": function() {
//     $(cursor).removeClass("is-active");
//     $(follower).removeClass("is-active");
//   }
// });





/*******
 * 
 * 
 * 
 * swiper
 *
****/
const swiperEls = document.querySelectorAll('.js-slider');
if (swiperEls) {
  let apconfig = isSmartPhone() ? false:  {
    delay: 4000, // 4秒後に次のスライドへ
    disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
  };
  for(swiperEl of swiperEls) {
    let slideCount = swiperEl.querySelectorAll('.swiper-slide').length;
    new Swiper(swiperEl, {
      loop: true,
      loopedSlides: slideCount,
      speed: (swiperEl.getAttribute('slider-speed') || 1000),
      slidesPerView: (swiperEl.getAttribute('slider-view') || 'auto'),
      // autoplay: apconfig,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      on: {
        slideChange: function (swiper) {
          $('.js-slider-min').text("0"+(swiper.realIndex + 1));
        },
        init: function(){
            $('.js-slider-max').text("0"+slideCount)
        },
      },
    })
  }
  // heroSlider.on('slideChange', function () {
  //   const scrollhint = hero.querySelector('.scroll-hint-icon-wrap')
  //   if (scrollhint) {
  //     const triggerEvent = new Event('scroll')
  //     hero.dispatchEvent(triggerEvent)
  //   }
  // });
}


let pcSwiperbool;
const pcSwiperEls = document.querySelectorAll('.js-pc-slider');
let pcSwiperList = [];
const initSwiperPc = () => {
  pcSwiperList = [];
  for(pcSwiperEl of pcSwiperEls) {
    let space = pcSwiperEl.getAttribute("data-slider-space") || 20;
    pcSwiperList.push(new Swiper(pcSwiperEl, {
      slidesPerView: 'auto',
      loop: true,
      speed: 1000,
      spaceBetween: Number(space),
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: false,
      // },
      loopedSlides: pcSwiperEl.querySelectorAll('.swiper-slide').length,
      pagination: {
        clickable: true,
        el: pcSwiperEl.querySelector('.swiper-pagination')
      },
      navigation: {
        nextEl: pcSwiperEl.querySelector('.swiper-button-next'),
        prevEl: pcSwiperEl.querySelector('.swiper-button-prev'),
      },
    }));
  }
}

const destroySwiperPC = () => {
  if (pcSwiperList) {
    for (pcSwiper of pcSwiperList) {
      pcSwiper.destroy(false, true);
    }
  }
};




if (!isSmartPhone()) {
  /*
  var $menu = $('.js-drw-items'),
      $item = $('.js-drw-item'),
      w = $(window).width(), //window width
      h = $(window).height(); //window height
  
  $(window).on('mousemove', function(e) {
    var offsetX = 0.5 - e.pageX / w, //cursor position X
        offsetY = 0.5 - e.pageY / h, //cursor position Y
        dy = e.pageY - h / 2, //@h/2 = center of poster
        dx = e.pageX - w / 2, //@w/2 = center of poster
        theta = Math.atan2(dy, dx), //angle between cursor and center of poster in RAD
        angle = theta * 180 / Math.PI - 90, //convert rad in degrees
        offsetPoster = $menu.data('offset') || 10,
        transformPoster = 'translate3d(0, ' + -offsetX * offsetPoster + 'px, 0) rotateX(' + (-offsetY * offsetPoster) + 'deg) rotateY(' + (offsetX * (offsetPoster * 2)) + 'deg)'; //poster transform
  
    //get angle between 0-360
    if (angle < 0) {
      angle = angle + 360;
    }
  
    //poster transform
    $menu.css('transform', transformPoster);
  
    //parallax for each layer
    $item.each(function() {
      var $this = $(this),
          offsetLayer = $this.data('offset') || 0,
          transformLayer = 'translate3d(' + offsetX * offsetLayer + 'px, ' + offsetY * offsetLayer + 'px, 20px)';
  
      $this.css('transform', transformLayer);
    });
  });
  */
}



let spSwiperList1 = [];
const spSwiperEls1 = document.querySelectorAll(".js-sp-swiper1");
const initSwiperSp = () => {
    if (spSwiperEls1 && spSwiperEls1.length !== 0) {
        for (spSwiper of spSwiperEls1) {
            let space = spSwiper.getAttribute('data-swiper-space') || 20;
            let loop = spSwiper.getAttribute('data-swiper-loop') || true;
            let speed = spSwiper.getAttribute('data-swiper-speed') || 1000;
            let perview = spSwiper.getAttribute('data-swiper-per-view') || 'auto';
            let pagenationTarget = spSwiper.getAttribute('data-swiper-pagination')
            let slideCount = spSwiper.querySelectorAll('.swiper-slide').length;
            let pagenationEl = pagenationTarget ? document.querySelector(pagenationTarget) : spSwiper.querySelector('.swiper-pagination');
            let pagenation = (pagenationEl) ? {
                el: pagenationEl,
                clickable: true,
            } : false;

            spSwiperList1.push(new Swiper(spSwiper, {
                loop: false,
                slidesPerView: perview,
                spaceBetween: Number(space),
                pagination: pagenation,
                speed: speed,
                navigation: {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev"
                },
                on: {
                  init: function(){
                    spSwiper.classList.add("slide-count-"+slideCount)
                    // if (slideCount<=1) {
                    //   spSwiper.querySelectorAll(".swiper-button-navi").style.display ="none"
                    // }
                  },
                },
            }));
        }
    }
}

const destroySwiperSp = () => {
    if (spSwiperList1.length != 0) {
        for (spSwiper of spSwiperList1) {
            spSwiper.destroy(false, true);
        }
        spSwiperList1 = [];
    }
}

let timeoutID = 0;
let delay = 500;
$(window).on('resize', function () {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {

      } else {
        // メディアクエリにマッチしたらスライダーを初期化
        if (isSmartPhone() && spSwiperList1.length == 0) {
            initSwiperSp();
        } else {
            destroySwiperSp();
        }
      }
    }, delay);
});
document.addEventListener('DOMContentLoaded',()=>{
  if (spSwiperEls1 && isSmartPhone()) {
      initSwiperSp();
  } else {
      destroySwiperSp();
  }
})